$(".send-btn").click(function() {
    var elem = $(this),
        item = elem.parent(),
        name = item.find("h4").text(),
        siblings = item.siblings(),
        fs_v = item.find("#fs-v").text(),
        m_time = item.find(".medic-job").text(),
        s_v = item.find("#s-v").text();
        
    var tags = item.find(".line .link").text(),
        image = siblings.find(".image").data("image");

    $(".medic-popup .tags .tag").text(tags);
    $(".medic-popup h4").text(name);
    $(".medic-popup .medic-job-time").text(m_time);
    $(".medic-popup .image").attr("src", image);
    $(".medic-popup span.s_v").text(s_v);
$(".medic-popup span.fs_v").text(fs_v);
    openPopup("medic-popup");
});


$(document).on('click', ".send-medic-btn", function () {
    var elem = $(this),
    item = elem.parent(),
    siblings = item.siblings(),
    name = item.find(".medic-info h4").text(),
    fs_v = item.find("#fs-v").text(),
    m_time = item.find(".medic-job").text(),
    s_v = item.find("#s-v").text(),
 tags = item.find(".line .link").text(),
    image = item.find(".image").data("image");


$(".medic-single-popup .tags .tag").text(tags);
$(".medic-single-popup .medic-job-time").text(m_time);
$(".medic-single-popup h4").text(name);
$(".medic-single-popup span.s_v").text(s_v);
$(".medic-single-popup span.fs_v").text(fs_v);
$(".medic-single-popup .image").attr("src", image);
    openPopup("medic-single-popup");
});


$(document).on("click", "#order-send", function(e) {
    var btn = $(this),
        form = btn.parents("form"),
        popup = form.parents(".popup"),
        inputs = form.find(".required");

    inputs.each(inputCheck);
    e.preventDefault();

    if (!form.find(".error").length) {
        var user_name = $("#user_name").val(),
            email = '',
            user_date = '',
            phone = $("#phone")[0].mask.unmaskedValue,
            medic = $('.user-block h4').text(),
            category = popup.find('.tags .tag').text(),
            visit_type = $("input[type=radio][name=visit]:checked").val(),
            date = popup.find('.day-item.active').text(),
            time = popup.find('.time-item.active').text(),
            visit_time = date + '   ' + time,
            avatar = popup.find('.user-block img').attr('src'),
            type = "Запись на прием";


        results = {
            user_name,
            phone,
            email,
            type,
            user_date,
            avatar,
            medic,
            category,
            visit_type,
            visit_time,
        };

        console.log(results);
        btn.attr("disabled", true);

        // sendAjax(
        //     "add_request",
        //     results,
        //     function() {
        //         btn.attr("disabled", false);
        //         inputs.each(resetInput);
        //         closePopup(popup);
        //         openPopup('success-popup')
        //     },
        //     function(res) {
        //         btn.attr("disabled", false);
        //         addNotif("Ошибка!", "Что-то пошло не так.", "error");
        //         displayErrors(res.text_error);
        //     }
        // );
    }

    e.preventDefault();
});




$(document).on("click", "#medic-send", function(e) {
    var btn = $(this),
        form = btn.parents("form"),
        popup = form.parents(".popup"),
        inputs = form.find(".required");

    inputs.each(inputCheck);
    e.preventDefault();

    if (!form.find(".error").length) {
        var user_name = $("#medic_name").val(),
            email = $("#medic_email").val(),
            user_date = $("#medic_date").val(),
            phone = $("#medic_phone")[0].mask.unmaskedValue,
            medic = $('.user-block h4').text(),
            category = popup.find('.tags .tag.active').text(),
            visit_type = $("input[type=radio][name=visit]:checked").val(),
            date = popup.find('.day-item.active').text(),
            time = popup.find('.time-item.active').text(),
            visit_time = date + ' ' + time,
            avatar = $('.user-block img').attr('src'),
            type = "Запись на прием";


        results = {
            user_name,
            phone,
            email,
            type,
            user_date,
            avatar,
            medic,
            category,
            visit_type,
            visit_time,
        };

        console.log(results);
        btn.attr("disabled", true);

        sendAjax(
            "add_request",
            results,
            function() {
                btn.attr("disabled", false);
                inputs.each(resetInput);
                closePopup(popup);
                openPopup('success-popup')
            },
            function(res) {
                btn.attr("disabled", false);
                addNotif("Ошибка!", "Что-то пошло не так.", "error");
                displayErrors(res.text_error);
            }
        );
    }

    e.preventDefault();
});




$(document).on("click", "#vacancy-send", function(e) {
    var btn = $(this),
        form = btn.parents("form"),
        popup = form.parents(".popup"),
        inputs = form.find(".required");

    inputs.each(inputCheck);
    e.preventDefault();


    if ((!form.find(".error").length)) {
        email = $("#vacancy_email").val(),
        type = "Отклик на вакансию",
        phone = $("#vacancy_phone")[0].mask.unmaskedValue,
        user_name = $("#vacancy_name").val(),
        product = $('#vacancy-title').text(),


        results = {
            email,
            type,
            phone,
            user_name,
            product
        };

        console.log(results);
        btn.attr("disabled", true);

        sendAjax(
            "add_request",
            results,
            function() {
                btn.attr("disabled", false);
                inputs.each(resetInput);
                closePopup(popup);
                openPopup('success-popup')
            },
            function(res) {
                btn.attr("disabled", false);
                addNotif("Ошибка!", "Что-то пошло не так.", "error");
                displayErrors(res.text_error);
            }
        );
    }

    e.preventDefault();
});

$(document).on("click", "#mail-send", function(e) {
    var btn = $(this),
        form = btn.parents("form"),
        inputs = form.find(".required");

    inputs.each(inputCheck);
    e.preventDefault();


    if ((!form.find(".error").length)) {
        email = $("#fs_email").val(),
            type = "Подписка на рассылку";


        results = {
            email,
            type,
        };

        console.log(results);
        btn.attr("disabled", true);

        sendAjax(
            "add_request",
            results,
            function() {
                btn.attr("disabled", false);
                inputs.each(resetInput);
                openPopup('success-mail-popup')
            },
            function(res) {
                btn.attr("disabled", false);
                addNotif("Ошибка!", "Что-то пошло не так.", "error");
                displayErrors(res.text_error);
            }
        );
    }

    e.preventDefault();
});

$(document).on("click", "#fs-send", function(e) {
    var btn = $(this),
        form = btn.parents("form"),
        inputs = form.find(".required");

    inputs.each(inputCheck);
    e.preventDefault();

    var user_remember = $('#user_remember:checked');

    if ((!form.find(".error").length)) {
        var user_name = $("#fs_name").val(),
            email = $("#fs_email").val(),
            phone = $("#fs_phone")[0].mask.unmaskedValue,
            type = "Заявка на консультацию";


        results = {
            user_name,
            phone,
            email,
            type,
        };

        console.log(results);
        btn.attr("disabled", true);

        sendAjax(
            "add_request",
            results,
            function() {
                btn.attr("disabled", false);
                inputs.each(resetInput);
                openPopup('success-popup')
            },
            function(res) {
                btn.attr("disabled", false);
                addNotif("Ошибка!", "Что-то пошло не так.", "error");
                displayErrors(res.text_error);
            }
        );
    }

    e.preventDefault();
});

$(document).on("click", "#help-send", function(e) {
    var user_name = $("#help-name").val(),
    phone = $("#help-phone")[0].mask.unmaskedValue,
    type = "Заявка на консультацию";

    $('.cf-name').val(user_name);
    var btn = $(this),
        form = btn.parents("form"),
        inputs = form.find(".required");

    inputs.each(inputCheck);
    e.preventDefault();

    if ((!form.find(".error").length)) {


        results = {
            user_name,
            phone,
            type,
        };



        console.log(results);
        btn.attr("disabled", true);

        sendAjax(
            "add_request",
            results,
            function() {
                btn.attr("disabled", false);
                inputs.each(resetInput);
                openPopup('success-popup')
            },
            function(res) {
                btn.attr("disabled", false);
                addNotif("Ошибка!", "Что-то пошло не так.", "error");
                displayErrors(res.text_error);
            }
        );
    }

    e.preventDefault();
});






$(window).scroll(headerScroll),
    $(document).on("click", ".open-menu", function() {
        $(".menu-block").slideToggle();

        $(this).toggleClass('active');
    })



// $(document).click(function (e) {
//     var t = $(".mobile-block");
//     t.is(e.target) || 0 !== t.has(e.target).length || $(".mobile-block").removeClass("show");
// })


