function calendar() {
    var now = new Date();
    var date = now.getDate();

    let select = document.querySelector(".calendar");
    var end = date + 6;

    for (let i = date; i <= end; i++) {
        $(".calendar div").first().text("Сегодня");
        $(".calendar div").first().addClass("active");
        now.setDate(now.getDate() + 1);
        var textout;
        var month = now.getMonth();
        var date = now.getDate();
        textout = date;

        let option = document.createElement("div");
        $(".calendar div").addClass("day-item");
        $(".calendar div").last().addClass("day-item");

        if (month == 0) month = " января";
        if (month == 1) month = " февраля";
        if (month == 2) month = " марта";
        if (month == 3) month = " апреля";
        if (month == 4) month = " мая";
        if (month == 5) month = " июня";
        if (month == 6) month = " июля";
        if (month == 7) month = " августа";
        if (month == 8) month = " сентября";
        if (month == 9) month = " октября";
        if (month == 10) month = " ноября";
        if (month == 11) month = " декабря";

        option.innerText = i + "  " + month;
        select.appendChild(option);
    }
}

calendar();

$(".calendar div").click(function() {
    $(".calendar div").removeClass("active");
    $(this).addClass("active");
});

$(".time-item").click(function() {
    $(".time-item").removeClass("active");
    $(this).addClass("active");
});

$(".time-item").first().addClass("active");






