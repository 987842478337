
function updateCart() {
    $('.cart-block').addClass('loading');
    simpleAjax('get_cart_info', function (res) {
        var info = JSON.parse(res),
            quantity = info['quantity'],
            price = info['price'],
            old_price = info['old_price'],
            discount = info['discount'],
            cart_items = info['cart_items'],
            promo_content = info['promo_content'];
            total_price =  price;


        if(quantity > 0) {
            $('.cart-count').addClass('active');

    
        }
        $('.cart-count').text(quantity);
        $('.cart-widget').attr('data-count', quantity);

        $('.old-price').text(old_price.toLocaleString())
        $('.cart-discount').text((old_price - price).toLocaleString())
        $('.cart-price').text(total_price.toLocaleString())



        $('.promo-wrapper').html(promo_content);

        $('')

        $('.cart-items').html(cart_items);
        $('.cart-block').removeClass('loading');
    })
}



function addToCart(id, quantity) {
    var btns = $('.add-to-cart[data-id="' + id + '"]');

    btns.attr('disabled', true);
    requestAjax('add_to_cart', {
        id,
        quantity
    }, function () {
        // addNotif('Корзина', 'Товар добавлен в корзину', 'success', '/cart');
        openPopup('success-mail-popup');
        updateCart();
        btns.attr('disabled', false);
    });
}

$(document).on('click', '.add-to-cart', function () {
    var elem = $(this),
        id = elem.data('id'),
        quantity = $('.quantity-field input[data-id="' + id + '"]');

    if (quantity.length) {
        quantity = parseInt(quantity.val());
    } else {
        quantity = 1;
    }

    addToCart(id, quantity);
})

$(document).on('click', '.clean-cart', function () {
    var btns = $('.clean-cart');

    btns.attr('disabled', true);
    simpleAjax('clean_cart', function () {
        btns.attr('disabled', false);
        updateCart();
    })
})

function removeFromCart(id) {
    requestAjax('remove_from_cart', id, function () {
        updateCart();
    })
}

$(document).on('click', '.remove-cart-item', function () {
    var elem = $(this),
        id = elem.data('id');

    removeFromCart(id);
})

var timer;




$(document).on('click', '.change-quantity', function () {
 
    var elem = $(this),
        input = elem.siblings('input'),
        id = input.data('id'),
        val = parseInt(input.val())

    if (isNaN(val)) {
        val = 1;
    }

    clearTimeout(timer)

    val = Math.round(val);

    if (elem.hasClass('plus')) {
        val++
    } else if (elem.hasClass('minus')) {
        val--
    }

    if (val < 1) {
        val = 1;
    } else if (val > 100) {
        val = 100;
    }

    input.val(val)

    res = {
        quantity: val, 
        id: id
    }

    timer = setTimeout(() => {
        requestAjax('change_quantity', res, function () {
            $('.cart-block').addClass('loading')
            updateCart();
            if(deliveryAddress){
                $('.delivery-price').val(deliveryAddress).change();
            }else{
                updateCart();
            }
        })
    }, 500);

})





  var timer;



  $(document).on('click', '#cart-request', function(e){
    var btn = $(this),
        form = $('#cart-form'),
                popup = form.parents(".popup"),
        inputs = form.find('.required');
    e.preventDefault()

        inputs.each(inputCheck)

        if(!form.find('.error').length){  
            user_name = $('#user_name').val(), 
            phone = $.trim($('#phone')[0].mask.unmaskedValue),
            total_price = $('.price').text(),
            visit_type = "",
            address = $('#city').val() +  ',' + $('#street').val() + ',' + $('#home').val(),
              arr = Array.from($(".cart-body .product-name"));

              if ($('#pay').is(':checked') ){
                visit_type = "Оплата наличными";
              }
        
             var products = [];
             function arrayUnique(arr){
                return arr.filter((e,i,a)=>a.indexOf(e)==i)
            }  

             for (var i = 0; i < arr.length; i++) {
               const item = arr[i];

      
               products.push(arr[i].innerHTML);
               arrayUnique(products)

             };



             var product = products.join();
            
            results = {
                user_name, 
                product,
                phone,
                total_price,
                visit_type,
                address
            }           
            console.log(results);
          
            requestAjax('add_request',
            results,
            function (res) {
                simpleAjax('clean_cart', function () {
                    closePopup(popup);
                    openPopup('success-popup')
                    }),
                    function () {
                        addNotif('Внимание', 'При оформлении заказа произошла неизвестная ошибка', 'error', '/cart');
                    }
            })
        }
    
})  
