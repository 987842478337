var price_range = $('#price-range'),
    min_input = $('#range_min'),
    max_input = $('#range_max'),
    pr_min = parseInt(price_range.data('min')), 
    pr_max = parseInt(price_range.data('max')),
    change_available = true;

price_range.slider({
    range: true,
    values: [ pr_min, pr_max ],
    min: pr_min,
    max: pr_max,
    step: 1,
    slide: function( event, ui ) {
        var values = ui.values;

        pr_min = values[0];
        pr_max = values[1];

        min_input[0].mask.unmaskedValue = pr_min.toString();
        max_input[0].mask.unmaskedValue = pr_max.toString();
    },
    change: function( event, ui ) {
        get_result2();
    }
})
  
function get_filter(){
    var filters = {};

    $('.select-sort').each(function(){
        var elem = $(this),
            slug = elem.data('slug'),
            checked = [],
            items = elem.find('input:checked')

        items.each(function(){
            var elem = $(this),
                id = elem.data('slug');

            checked.push(id);
        })

        if(checked.length){
            filters[slug] = checked;
        }
    })

    return filters;
}

function get_tax(){
    var wrapper = $('section.archive'),
        tax = wrapper.data('tax'),
        val = wrapper.data('val'),
        results = '';

    if(tax && val){
        results = {
            tax,
            val
        };

        results[tax] = val;
    }
 
    return results;
}

function get_sort(){
    var sort = $('#sort').attr('data-id');

    if(sort){
        return sort
    }
}

$('#sort, .select-sort').change(get_result2);

$('.select-sort input').change(get_result2);
 
function get_result2(){
    var filters = get_filter(),
        min = pr_min,
        max = pr_max,
        wrapper = $('.medic-filters.paste-loading'),
        content = $('.medic-filters').eq(0),
        tax = get_tax(),
        search = $.trim($('.archive .search-field').val()),      
        sort = get_sort(),
        results = {filters, min, max, tax, search, sort};

    wrapper.addClass('loading');
    requestAjax('get_sort_items', results, function(res){
        wrapper.removeClass('loading');
        content.html(res);
    })
}




