function headerScroll(){
    if($(window).scrollTop()){
        $('header.site-header').addClass('scroll')
    }else{
        $('header.site-header').removeClass('scroll')
    }
}

headerScroll()

$(window).scroll(headerScroll);

$(document).on('click', '.open-menu', function(){
    $('.mobile-wrapper').toggle('show')
})

$(document).on('click', '.close-menu', function(){
    $('.mobile-wrapper').removeClass('show')
})

$(document).click(function (e) {
    var menu = $('.menu-group')

    if ( !menu.is(e.target) && menu.has(e.target).length === 0) {
        $('.mobile-wrapper').removeClass('show')
    }
})


$(document).on('click', '.show-all', function () {
    $('.content-show').slideToggle(300);
    $('.second-image').toggleClass('show');
    $('.certificate').slideToggle(300);
    $('.section-title.dn').removeClass('dn');
    
})



$(function() {
 
    $(window).scroll(function() {
     
    if($(this).scrollTop() != 0) {
     
    $('#toTop').fadeIn();
     
    } else {
     
    $('#toTop').fadeOut();
     
    }
     
    });
     
    $('#toTop').click(function() {
     
    $('body,html').animate({scrollTop:0},800);
     
    });
     
    });
     