function sendAjax(name, data, success, error) {
    jQuery.post('/wp-admin/admin-ajax.php', {
        action: name,
        data: data
    },
        function (res) {

            try {
                JSON.parse(res)
            } catch (e) {
                $('.popup').fadeOut(400)
                $('.error-code').html($.trim(res))
                $('.popup.error-popup').fadeIn(400)

                return false
            }

            var json = JSON.parse(res)

            if (json.result == 'success') {
                success(json)
            } else if (json.result == 'error' && error) {
                error(json)
            } else {
                $('.popup').fadeOut(400)
                $('.error-code').html($.trim(res))
                $('.popup.error-popup').fadeIn(400)
            }
        }
    )
}

function simpleAjax(name, func) {
    jQuery.post('/wp-admin/admin-ajax.php', {
        action: name,
    },
        function (res) {
            func(res);
        }
    );
}

function postAjax(name, data) {
    jQuery.post('/wp-admin/admin-ajax.php', {
        action: name,
        data: data
    });
}

function requestAjax(name, data, func) {
    jQuery.post('/wp-admin/admin-ajax.php', {
        action: name,
        data: data
    },
        function (res) {
            func(res);
        }
    );
}

function unmaskedPhone(val) {
    return val.replace(/[^0-9]/g, '')
}