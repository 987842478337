$(document).ready(function () {
    
$(".fs-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    dots:true,
    speed: 500,
    prevArrow: '<div class=" prev"><img src="wp-content/themes/custom/img/leftw.svg" alt=""></div>',
    nextArrow: '<div class="next"><img src="wp-content/themes/custom/img/rightw.svg" alt=""></div>',
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]

    
});


    
$(".gallery-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
});

$(".slider-wrapper").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    speed: 500,
    prevArrow: '<div class=" prev"><img src="wp-content/themes/custom/img/leftw.svg" alt=""></div>',
    nextArrow: '<div class="next"><img src="wp-content/themes/custom/img/rightw.svg" alt=""></div>',
    responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
});

$(".product-slider").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 500,
    prevArrow: '<div class=" prev"><img src="wp-content/themes/custom/img/left.svg" alt=""></div>',
    nextArrow: '<div class="next"><img src="wp-content/themes/custom/img/right.svg" alt=""></div>',

    responsive: [{
        breakpoint: 1024,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
        }
    },
    {
        breakpoint: 767,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        }
    }
]
});

$(".coffee-slider").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 500,
    prevArrow: '<div class=" prev"><img src="wp-content/themes/custom/img/left.svg" alt=""></div>',
    nextArrow: '<div class="next"><img src="wp-content/themes/custom/img/right.svg" alt=""></div>',

    responsive: [{
        breakpoint: 1024,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
        }
    },
    {
        breakpoint: 767,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        }
    }
]
});
$(".acc-slider").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 500,
    prevArrow: '<div class=" prev"><img src="wp-content/themes/custom/img/left.svg" alt=""></div>',
    nextArrow: '<div class="next"><img src="wp-content/themes/custom/img/right.svg" alt=""></div>',

    responsive: [{
        breakpoint: 1024,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
        }
    },
    {
        breakpoint: 767,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        }
    }
]
});
});



$(".reviews-grid").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    speed: 500,
    prevArrow: '<div class=" prev"><img src="wp-content/themes/custom/img/left.svg" alt=""></div>',
    nextArrow: '<div class="next"><img src="wp-content/themes/custom/img/right.svg" alt=""></div>',

});




$(".team-grid").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    speed: 500,
    prevArrow: '<div class=" prev"><img src="/wp-content/themes/custom/img/leftw.svg" alt=""></div>',
    nextArrow: '<div class="next"><img src="/wp-content/themes/custom/img/rightw.svg" alt=""></div>',
    responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
});
